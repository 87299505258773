import React from "react";
import './NotFoundPage.css';
import logoWordmark from "../../assets/images/svg/logo-wordmark.svg";

const NotFoundPage = () => {
  return (
      <div className='container'>

          <img
              className='logo'
              src={logoWordmark}
              alt="logo"/>

      </div>
  );
};

export default NotFoundPage;
