import './PublicProfilePage.css';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Suspense } from 'react';
import { useUserProfileFetch } from '../../hooks/api/useUserProfileFetch';
import Loader from './../../components/Loader/Loader';
import { UserProvider } from './../../UserContext';

const BottomSection = React.lazy(() =>
  import('../../components/Containers/BottomSection/BottomSection')
);
const MiddleSection = React.lazy(() =>
  import('./../../components/Containers/MiddleSection/MiddleSection')
);

const TopSection = React.lazy(() =>
  import('./../../components/Containers/TopSection/TopSection')
);

const PublicProfilePage = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');
  let profile = useUserProfileFetch(id);

  useEffect(() => {
    ReactGA.set({ page: '/profile' });
    ReactGA.pageview('/profile');
  });

  let slides = profile.events;

  return (
    <>
      <UserProvider>
        <div className="container">
          <Suspense fallback={<Loader />}>
            <TopSection profile={profile} />
            <MiddleSection />
            <BottomSection slides={slides} />
          </Suspense>
        </div>
      </UserProvider>
    </>
  );
};

// test

export default PublicProfilePage;
