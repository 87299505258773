import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import HomePage from './views/HomePage/HomePage';
import NotFoundPage from './views/NotFoundPage/NotFoundPage';
import history from './routes/history';
import './App.css';
import ReactGA from 'react-ga';
import PublicProfilePage from './views/PublicProfilePage/PublicProfilePage';
import AdminPage from './views/AdminPage/AdminPage';

const trackingId = 'UA-187195938-1'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const App = () => {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/admin" component={AdminPage} />
          <Route exact path="/profile" component={PublicProfilePage} />
          <Route path="" component={NotFoundPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
