import React from 'react';
import './AdminPage.scss';
import Amplify from 'aws-amplify';
import {
  Authenticator,
  Image,
  Text,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import '@aws-amplify/ui-react/styles.css';
import awsMobile from './../../aws-exports';
import Logo from '../../assets/images/svg/logo-wordmark.svg';
import UserPage from '../../components/UserPage/UserPage';

Amplify.configure(awsMobile);

const AdminPage = () => {
  const { t } = useTranslation();
  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Rollback logo" src={Logo} width="200px" />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  };

  return (
    <div className="container">
      <Authenticator
        signUpAttributes={['email', 'name']}
        components={components}
      >
        {({ signOut, user }) => {
          return (
            <>
              <button className="signout-button" onClick={signOut}>
                {t('SIGNOUT')}
              </button>
              <UserPage user={user} />
            </>
          );
        }}
      </Authenticator>
    </div>
  );
};

export default AdminPage;
