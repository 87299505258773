import React from 'react';
import './Loader.scss';
import { ReactComponent as LogoWordMark } from '../../assets/images/svg/logo-wordmark.svg';

const Loader = () => {

  return (
    <div className="loading-container">
        <LogoWordMark className="rollback-loading-logo"/>
    </div>
  );
};

export default Loader;
