import './HomePage.css';
import React, {useEffect} from "react";
import ReactGA from "react-ga";

const HomePage = () => {

  useEffect(() => {
    ReactGA.set({page: '/'});
    ReactGA.pageview('/');
  });

  return (
      <div className='container'>
        <iframe id="typeform-full" width="100%" height="100%" frameBorder="0"
                title='typeform'
                allow="camera; microphone; autoplay; encrypted-media;"
                src="https://form.typeform.com/to/u81nEeU5?typeform-medium=embed-snippet"/>
      </div>
  );
};

export default HomePage;
