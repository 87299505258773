import React from 'react';
import { useTranslation } from 'react-i18next';
import './UserPage.scss';

const UserPage = (user) => {
  const { t } = useTranslation();

  return (
    <div className="user-container">
      <div className="events-and-avatar">
        <div className="user-events">
          <ul>
            <li>{t('LOEVENTS')}</li>
          </ul>
        </div>

        <div className="user-profile-avatar">
          <span>{user.user.username}</span>
        </div>
      </div>
      <div className="events-handlers">
        <button>{t('ADDEVENT')}</button>
        <button>{t('DELEVENT')}</button>
        <button>{t('CREATEEVENT')}</button>
      </div>
    </div>
  );
};

export default UserPage;
