import { createContext, useState } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [eventImages, setEventImages] = useState(null);
  const [fullScreenState, setFullScreenState] = useState(false);

  const toggleGallerySlide = (eventImages) => {
    setEventImages(eventImages);
  };

  const handleCloseFullScreen = () => {
    setFullScreenState(!fullScreenState);
  };

  return (
    <UserContext.Provider
      value={{
        eventImages,
        fullScreenState,
        setFullScreenState,
        toggleGallerySlide,
        handleCloseFullScreen,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
